"use client"

import logger from "@/logger";
import {useEffect} from "react";

export default function RefreshSession() {
  useEffect(() => {
    setInterval(() => {
      fetch('/api/refresh-session', {method: 'GET', credentials: 'include'})
        .then(r => logger.debug(`Refresh session status: ${r.status}`))
        .catch(e => logger.error(`Failed to refresh session: ${e}`))
    }, 1000 * 60 * 5)
  }, [])
  return (<> </>)
}
